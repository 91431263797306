.first-desc {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    font-family: "PloniBold";
    line-height: 1.32;
    margin-bottom: 25px !important;
}

.title {
    text-align: center;
    font-size: 24px;
    font-family: "PloniBold";
    line-height: normal;
}

.first-title {
    color: var(--6123-d-0, #6123D0);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2px;
}

.purpule {
    color: var(--6123-d-0, #6123D0);
}

.sub-desc {
    color: var(--000000, #000);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 85%;
    margin: 0 auto;
}

.desc-thx {
    font-size: 22px !important;
    margin-bottom: 10px !important;
}


.max-260 {
    max-height: 260px;
}