#login-page {
    text-align: center;
}

ion-input {
    border: 1px solid #D1D5DB;
    border-radius: 50px;
    max-width: 80%;
    margin: auto;
    background: white;
    caret-color: unset !important;
    --highlight-color-focused: unset !important
}

ion-button {
    width: 80%;
}


.connexionTitle {
    font-size: 32px;
    font-family: "PloniBold";
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
}

.connexionTitle p {
    width: 80%;
    margin: auto;
}

.sms-text {
    margin-bottom: 20px;
    width: 80%;
    margin: auto;
}

.login-desc {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    width: 80%;
    margin: auto;
}

.login-desc p {
    width: 80%;
    margin: auto;
    line-height: 1.4;
    padding-top: 50px;
}

.tel-input,
.sms-input {
    font-family: "PloniBold";
    height: 60px;
    font-size: 24px;
    max-width: 90% !important;
  /*  margin-top: 15px;*/
}

.sms-input {
    border: 1px solid black !important;
}

ion-checkbox::part(container) {
    border-radius: 3px;
}

.confirmMail-cbx ion-checkbox::part(container) {
    margin-top: 8px;
}
/*
.confirmMail-cbx {
    display: flex;
    padding-right: 0;
    justify-content: center;
}
*/
.send-btn {
    width: 90% !important;
    margin-top: 5px;
}

input {
    caret-color: black !important;
}

ion-checkbox {
    --background-checked: white !important;
    --border-color: black !important;
    --border-color-checked: black !important;
    --checkbox-background-checked: black !important;
    --size: 18px;
}

.confirm-cbx {
    text-align: right;
    margin-top: 18px;
    padding-right: 0;
    margin-bottom: 15px;
}

.resend-btn {
    color: #6123D0 !important;
}
.policy-text {
    text-align: right;
}