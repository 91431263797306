.no-margin {
  margin: 0 0 0 0;
}

.searchB {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-center {
  text-align: center;
}

.rec-btn {
  width: 80%;
  margin: auto;
  height: 40px;
  display: block;
  --border-radius: 50px;
  --background-activated: #00e3cf;
  --background-focused: #00e3cf;
  --background-hover: #00e3cf;
  --background: linear-gradient(255.76deg, #6CDFCA -35.38%, #9CEDDA 95.61%) !important;
  color: black;
  border-radius: 50px;
  box-shadow: 0px 6px 18px 0px rgba(65, 133, 120, 0.20);
  font-weight: bold;
  font-size: 20px;
  height: 60px;
  margin-top: 20px;
}

.heart-img {
  justify-content: center;
}

.heart-img img {
  width: 40px;
}

.drName {
  font-size: 28px;
  color: black;
  font-family: "PloniBold";
}

.DrDomain {
  color: #79797e;
  font-size: 15px;
  font-weight: 500;
}

.DrAddress {
  color: #4a4a4a;
  font-weight: 300;
  margin-top: 7px;
  display: inline-block;
}


.searchRes {
  margin: 0 14px;
  border: 1px solid #e7e7f4;
  border-radius: 5px;
  z-index: 200;
  width: 100%;
}

.searchRes ion-item {
  border-bottom: 1px solid #E7EEF4;
  color: #666678;
  --background: transparent;
  padding: 13px 0;
  font-size: 18px;
  margin: 0 18px;
}

.searchRes em {
  color: #000;
  font-weight: bold;
}

.searchRes ion-item .itemTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-right: 5px;
}

.searchRes ion-item .itemTitle {
  color: #666678;
  font-size: 20px;
  font-family: "PloniBold";
}

.searchRes ion-item .itemTitle .cat {
  font-size: 16px !important;
  font-family: "Ploni" !important;
}

.recommTextarea {
  background: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 0 15px 10px;
  --placeholder-color: #9797a5;
  --placeholder-opacity: 1;
  width: 80%;
  margin: auto;
  min-height: 142px !important;
  max-height: 142px !important;
  margin-top: 20px;
  box-shadow: -4px 16px 10px 0px #A6A6A608;
  font-size: 20px;
  --highlight-color-focused: unset !important;
}

textarea {
  --placeholder-color: #4A4A4A !important;
  padding-top: 14px !important;
  caret-color: black !important;
}

textarea:focus {
  --placeholder-color: transparent !important;
}

ion-header {
  height: 3% !important;
}

ion-toolbar {
  --background: transparent !important;
}

.MuiStepLabel-iconContainer svg {
  color: white;
  border: 1px var(--d-1-d-5-db, #D1D5DB) solid;
  border-radius: 50%;
}

.MuiStepLabel-iconContainer svg text {
  fill: var(--d-1-d-5-db, #D1D5DB);
  font-size: 18px;
}

.stepper-col {
  padding-left: 0 !important;
}

.flag-col {
  display: flex;
  align-items: center;
  padding-right: 0 !important;
}

.flag-img {
  margin-top: -4px;
  position: absolute;
  left: 6%;
}

.flag-line {
  width: 90%;
  height: 1px;
  border: 1px solid #D1D5DB;
}

.spinner-img {
  position: absolute;
  top: -14px;
  left: 3px;
}

.active-line {
  border: 1px solid #6123D0 !important;
}

.cat-card {
  padding: 15px 0 15px 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px !important;
  color: var(--000000, #000);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  height: 60px;
  width: 43%;
  /*max-width: 163px;*/
  border-radius: 10px;
  border: 1px solid #E7EEF4;
  margin: 10px 0;
}

ion-searchbar {
  border: 1px solid #D1D5DB;
  border-radius: 50px;
  max-width: 95%;
  margin: auto;
  background: white;
  caret-color: unset !important;
  --box-shadow: unset !important;
  border: 1px solid var(--000000, #000);
}

ion-list {
  width: 90% !important;
  margin-top: 15px !important;
  box-shadow: -4px 16px 10px 0px #A6A6A608;
  border-radius: 10px !important;
  overflow-x: auto;
  max-height: 468px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 69px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6123D0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6123D0;
}

.category-title {
  color: var(--000000, #000);
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-family: "PloniBold";
  width: 80% !important;
  margin: auto;
  margin-top: 30px;
}

.ion-searchbar {
  width: 90% !important;
}

ion-searchbar .searchbar-input {
  font-size: 22px !important;
}

.sc-ion-searchbar-ios-h {
  --background: unset !important;
  margin-top: 10px;
}

.header-ios {
  background: unset !important;
}

.choose-cat {
  font-size: 18px;
  color: #666678;
}

.stepper-row {
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  position: relative;
  z-index: 20;
}

.stepper-re {
  margin-top: 35px;
}

.MuiStepLabel-iconContainer svg {
  width: 32px;
  height: 32px;
  border: 2px solid #D1D5DB !important;
}

.Mui-active {
  color: transparent !important;
}

.Mui-active.MuiStepLabel-iconContainer svg text {
  fill: #6123D0 !important;
}

.MuiSvgIcon-root.Mui-active {
  border: 2px solid #6123D0 !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed {
  fill: #6123D0 !important;
  border: none !important;
}

.MuiStepConnector-root.Mui-completed .MuiStepConnector-line {
  border: 1.5px solid #6123D0 !important;
}

.MuiStepLabel-iconContainer svg text {
  font-size: 14px !important;
  font-family: "PloniBold";
}

.MuiStep-root {
  padding: 0 !important;
}

.MuiStepLabel-iconContainer {
  padding: 0 !important;
}

ion-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

ion-list .item-native {
  padding-right: 0 !important;
}