.step-content {
    text-align: center;
    /*animation: fadeInOut 0.5s ease-in-out forwards;*/

}

.title {
    font-family: "PloniBold";
    font-size: 26px;
    font-weight: 700;
    line-height: 35px;
    color: black;
}

.desc {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    max-width: 300px;
    margin: 0 auto;
}

.jump-btn {
    font-weight: 600;
    font-size: 16px;
    width: 118px;
    height: 60px;
    --ion-color-base: black !important
}

.next-btn {
    --background: linear-gradient(255.76deg, #6CDFCA -35.38%, #9CEDDA 95.61%) !important;
    border-radius: 50px;
    box-shadow: 0px 6px 18px 0px rgba(65, 133, 120, 0.20);
    color: black;
    font-weight: bold;
    font-size: 20px;
    height: 60px;
    --border-radius: 50px;
}

.next-btn-row {
    width: 100%;
    /*margin-bottom: 30px;*/
    margin-top: 30px;
}

.end-btn {
    margin-top: 5px !important;
}

ion-button {
    font-size: 18px;
    font-family: "PloniBold";
    width: 90%;
}

.stepper {
    margin: auto;
    width: 72px;
    background-color: #fafbff !important;
}

.thank-txt {
    font-family: "PloniBold";
    font-size: 26px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.thank-txt.end {
    margin-top: -20px !important;
}

.final-note {
    font-size: 19px;
    width: 90%;
    margin: auto;
    color: #666678;
    margin-top: -15px;
}

@keyframes fadeInOut {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.max-260 {
    max-height: 260px;
}