ion-button {
  width: 100%;
}

#home-page {
  text-align: center;
}

.logo {
  margin-top: 20px;
  margin-bottom: 22px;
  width: 170px;
  margin-left: 5%;
}

@media only screen and (min-width: 1000px)  {
  .logo {
    margin-left: 0% !important;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiMobileStepper-root.MuiMobileStepper-positionStatic.stepper {
  background-color: white !important;
}

.MuiMobileStepper-dot.MuiMobileStepper-dotActive {
  background-color: #6123D0 !important;
}

.thank_all {
  margin-top: -60px;
}